import https from '../init/https';

// CMDS-ARL-01: List all ARL Mappings
export const fetchARLList = async (board_id, course_id, subject_id, topic_id, searchTerm, limit, offset) => {
  try {
    let condition = [],
      cond = '';
    if (limit) {
      condition.push(`limit=${limit}`);
    }
    condition.push(`offset=${offset}`);
    if (board_id && +board_id !== -1) {
      condition.push(`board_id=${board_id}`);
    }
    if (course_id && +course_id !== -1) {
      condition.push(`course_id=${course_id}`);
    }
    if (subject_id && +subject_id !== -1) {
      condition.push(`subject_id=${subject_id}`);
    }
    if (topic_id && +topic_id !== -1) {
      condition.push(`topic_id=${topic_id}`);
    }
    if (searchTerm && searchTerm !== '') {
      condition.push(`search_text=${searchTerm}`);
    }

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    const tableData = await https.get(`/arl_mapping/all${cond}`);
    // console.log('tableData', tableData);
    return tableData?.data?.data;
  } catch (err) {
    console.log(err);
    if (err) {
      throw err;
    }
  }
};

// CMDS-ARL-01-01: Get Count of ARL Mapping Summary list
export const fetchARLListCount = async (board_id, course_id, subject_id, topic_id, searchTerm) => {
  try {
    let condition = [],
      cond = '';
    // if (limit) {
    //   condition.push(`limit=${limit}`);
    // }
    // condition.push(`offset=${offset}`);
    if (board_id && +board_id !== -1) {
      condition.push(`board_id=${board_id}`);
    }
    if (course_id && +course_id !== -1) {
      condition.push(`course_id=${course_id}`);
    }
    if (subject_id && +subject_id !== -1) {
      condition.push(`subject_id=${subject_id}`);
    }
    if (topic_id && +topic_id !== -1) {
      condition.push(`topic_id=${topic_id}`);
    }
    if (searchTerm && searchTerm !== '') {
      condition.push(`search_text=${searchTerm}`);
    }

    if (condition.length > 0) {
      cond = `?${condition.join('&')}`;
    }
    const count = await https.get(`/arl_mapping/summary_list/count${cond}`);
    // console.log('count', count);
    return count?.data?.data?.sub_topic_contents_count;
  } catch (err) {
    console.log(err);
    if (err) {
      throw err;
    }
  }
};

// CMDS-ARL-02: Get Objectives for Sub Topic
export const fetchObjectivesForSubtopic = async (subTopicId) => {
  try {
    const result = await https.get(`arl_mapping/sub_topic_objective_criteria?sub_topic_id=${subTopicId}`);
    // console.log('result', result);
    return result?.data?.data;
  } catch (err) {
    console.log(err);
    if (err) {
      throw err;
    }
  }
};

// CMDS-ARL-04: Get List of board objectives
export const fetchARLBoardObjectives = async (boardId) => {
  try {
    const result = await https.get(`/arl_mapping/objctives?board_id=${boardId}`);
    // console.log('result', result);
    return result?.data?.data;
  } catch (err) {
    console.log(err);
    if (err) {
      throw err;
    }
  }
};

//CMDS-ARL-03: Put Subtopic Objective criteria details
export const editSubtopicObjectiveConditions = async (formData) => {
  try {
    let result = await https.put(`/arl_mapping/sub_topic_objective_criteria`, formData);
    // console.log('result', result, result?.data);
    return result?.data;
  } catch (err) {
    throw err;
  }
};
