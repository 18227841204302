import React from 'react';
import './DropDown.css';

const DropDown = (props) => {
  const {
    default_option,
    values,
    id_name,
    display_name,
    handleIdChange,
    withinModal,
    name,
    updateUserId,
    updateUserValue,
    marginTop,
    display_eng_name,
    selectedId,
  } = props;

  const handleAuthorIdChange = async (e) => {
    let value = e.target.name;
    let id = e.target.value;
    if (value === 'Author') {
      updateUserId(id);
    }

    values.forEach((obj) => {
      // eslint-disable-next-line
      if (obj.user_id == id) {
        obj.selected = true;
      } else {
        delete obj.selected;
      }
    });
    await updateUserValue(values);
  };

  return (
    <select
      className={withinModal ? 'dropdown-modal' : 'dropdown'}
      style={{ marginTop: marginTop ? marginTop : null }}
      onChange={withinModal ? handleAuthorIdChange : handleIdChange}
      name={name}
      value={selectedId}>
      <option value={-1}>{default_option}</option>
      {values &&
        values.map((ele, index) => {
          let displayName = ele[display_eng_name] ? `${ele[display_name]} - ${ele[display_eng_name]}` : `${ele[display_name]}`;
          if (ele.selected) {
            return (
              <option key={index} value={ele[id_name]} selected>
                {displayName}
              </option>
            );
          } else {
            return (
              <option key={index} value={ele[id_name]}>
                {displayName}
              </option>
            );
          }
        })}
    </select>
  );
};
export default DropDown;
