/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import './ARLTable.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import NoDataFound from '../../Assets/NoDataFound.png';
import condition_true from '../../Assets/Table/TableBody/condition_true.svg';
import condition_false from '../../Assets/Table/TableBody/condition_false.svg';
import moment from 'moment';

const ARLTable = (props) => {
  const { tableData, count, limit, offset, currentPage, setOffset, setCurrentPage } = props;
  const history = useHistory();
  const listRef = useRef();
  const initialRender = useRef(false);
  const tableLength = tableData ?? JSON.stringify(tableData.length);

  useEffect(() => {
    setOffset(limit * (currentPage - 1));

    if (initialRender.current && listRef.current && tableData.length > 12) {
      const { clientHeight, scrollHeight } = listRef.current;
      const percentageFactor = (tableData.length - 10) / tableData.length;
      listRef.current.scrollTo({ top: scrollHeight * percentageFactor - clientHeight * 0.85, behaviour: 'smooth' });
    } else {
      initialRender.current = true;
    }
  }, [currentPage, tableLength, limit, tableData.length]);

  const columns = [
    { column_key: 'id', columnName: 'Sub topics', hasSort: true, columnType: 'text' },
    { column_key: 'title', columnName: 'Number of Content', hasSort: true, columnType: 'text' },
    { column_key: 'date', columnName: 'Date', hasSort: true, columnType: 'text' },
    { column_key: 'duration', columnName: 'Total Duration', hasSort: true, columnType: 'text' },
    { column_key: 'condition', columnName: 'Condition', hasSort: true, columnType: 'img' },
  ];

  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      const isNearBottom = scrollHeight - scrollTop - 10 < clientHeight;
      if (isNearBottom && offset <= count - limit) {
        setCurrentPage(currentPage + 1);
        listRef.current.scrollTo({ top: 0, behaviour: 'smooth' });
      }
    }
  };

  const handleSubTopicClick = (obj) => {
    history.push({ pathname: `arl-mapping-details/${obj.sub_topic_id}`, state: obj });
  };

  return (
    <div className="arl_table" style={{ marginLeft: '1rem', alignItems: 'flex-start' }}>
      {/* <TableControlButtons /> */}
      <div className="table-control__count" style={{ marginLeft: '0', marginBottom: '1rem' }}>
        {count && (count === 0 || count === 1) ? `${count} record` : `${count} records`}
      </div>
      <div className="arl_table-responsive" onScroll={handleScroll} ref={listRef}>
        <table className="arl_table-display">
          <thead>
            <tr style={{ display: !tableData?.length && 'inline-table', zIndex: '1' }}>
              {columns.map((ele, index) => {
                return (
                  <td key={index} className="arl-table-column-heading-text">
                    {ele.columnName}
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableData?.length > 0 ? (
              tableData.map((obj, index) => {
                const date = obj?.last_updated_date;
                const formatedDate = date ? moment(date).format('DD MMM YYYY') : '--';
                return (
                  <tr key={index} onClick={() => handleSubTopicClick(obj)}>
                    <td>
                      {obj.sub_topic_name_eng ? `${obj?.sub_topic_name} - ${obj.sub_topic_name_eng}` : `${obj?.sub_topic_name}`}
                    </td>
                    <td>{obj?.content_count}</td>
                    <td>{formatedDate}</td>
                    <td>{obj?.total_duration ? obj?.total_duration : 'NA'}</td>
                    <td>
                      {obj?.condition ? (
                        <img src={condition_true} alt="condition-defined" />
                      ) : (
                        <img src={condition_false} alt="condition-not-defined" />
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan={6}>
                  <img src={NoDataFound} alt="no_data_found" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ARLTable;
