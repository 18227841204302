/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './DisplayARLDetail.css';
import { Link, useLocation } from 'react-router-dom';
import ArrowLeft from '../../../../Assets/ContentLibrary/ContentDetails/arrow-left.png';
import ARLCardHolder from '../../../../components/ARLCardHolder/ARLCardHolder';
import RiseLoader from 'react-spinners/RiseLoader';
import promiseAllCall from '../../../../utils/promise';
import { fetchSubTopics } from '../../../../api/master_data';
import { fetchARLBoardObjectives, fetchObjectivesForSubtopic } from '../../../../api/arl_mapping';

const DisplayARLDetail = () => {
  const location = useLocation();
  const selectedARLSubtopic = location.state;
  const [loading, setLoading] = useState(false);
  const [objectiveData, setObjectiveData] = useState([]);
  const [popOverObjectiveList, setPopOverobjectiveList] = useState([]);
  const [popOverSubTopicList, setPopOverSubTopicList] = useState([]);
  const [updateData, setUpdateData] = useState(false);
  // console.log('selectedARLSubtopic', selectedARLSubtopic);

  useEffect(() => {
    const fetchDropdowns = async () => {
      try {
        const callAll = await promiseAllCall([
          fetchARLBoardObjectives(selectedARLSubtopic?.board_id),
          fetchSubTopics(
            selectedARLSubtopic?.board_id,
            selectedARLSubtopic?.course_id,
            selectedARLSubtopic?.subject_id,
            selectedARLSubtopic?.sub_subject_id,
            selectedARLSubtopic?.topic_id,
          ),
        ]);
        // console.log('callAll', callAll);
        setPopOverobjectiveList(callAll[0]);
        setPopOverSubTopicList(callAll[1]);
      } catch (err) {
        console.log('err in fetchDropdowns', err);
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    fetchDropdowns();
  }, []);

  useEffect(() => {
    const fetchObjDetails = async () => {
      try {
        const res = await fetchObjectivesForSubtopic(selectedARLSubtopic.sub_topic_id);
        // console.log('res', res);
        setObjectiveData(res);
      } catch (err) {
        console.log('err in fetchObjectivesForSubtopic', err);
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    fetchObjDetails();
  }, [updateData]);

  // console.log('objectiveData', objectiveData);
  const triggerUpdate = () => {
    setUpdateData(!updateData);
  };

  return (
    <>
      {loading ? (
        <div className="loading__spinners">
          <RiseLoader
            color="#CC435F"
            margin={5}
            cssOverride={{
              position: 'absolute',
              top: '50%',
              left: '45%',
              zIndex: 1,
            }}
            loading={loading}
            size={50}
            speedMultiplier={1}
          />
        </div>
      ) : null}
      <div className="display-activity">
        <div className="display-content__header">
          <Link to="/arl-mapping">
            <img className="header-img" src={ArrowLeft} alt="back to arl-mapping" />
          </Link>
          <div className="header-text">
            ARL Mapping {'>'}{' '}
            <span style={{ textTransform: 'none' }}>
              {(selectedARLSubtopic?.sub_topic_name_eng
                ? `${selectedARLSubtopic?.sub_topic_name} - ${selectedARLSubtopic?.sub_topic_name_eng}`
                : `${selectedARLSubtopic?.sub_topic_name}`) || 'NA'}
            </span>
          </div>
        </div>
        <div className="arl-detail-container">
          <h3 className="arl-detail_header-text">
            {(selectedARLSubtopic?.sub_topic_name_eng
              ? `${selectedARLSubtopic?.sub_topic_name} - ${selectedARLSubtopic?.sub_topic_name_eng}`
              : `${selectedARLSubtopic?.sub_topic_name}`) || 'NA'}
          </h3>
          <div className="arl-card-holder">
            {objectiveData?.length > 0 ? (
              objectiveData.map((ele) => {
                return (
                  <ARLCardHolder
                    cardHeading={ele.objective_name}
                    key={ele.objective_id}
                    cardObjectiveId={ele.objective_id}
                    selectedSubTopic={selectedARLSubtopic.sub_topic_id}
                    objectiveData={ele}
                    popOverObjectiveList={popOverObjectiveList}
                    popOverSubTopicList={popOverSubTopicList}
                    triggerUpdate={triggerUpdate}
                  />
                );
              })
            ) : (
              <div>{'No Objectives found for selected subTopic'}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DisplayARLDetail;
