import React from 'react';

const ARLInputs = (props) => {
  const { value, textName, name, handleInputs, defineCondition } = props;
  return (
    <div
      className="arl-condition-input-wrapper"
      style={{ opacity: !defineCondition ? 0.5 : 1, pointerEvents: !defineCondition }}
      disabled={!defineCondition}>
      <div>{textName}</div>
      <input
        type="text"
        value={value}
        className="arl-condition-input"
        name={name}
        onChange={handleInputs}
        disabled={!defineCondition}
      />
    </div>
  );
};

export default ARLInputs;
