import React, { useEffect, useState } from 'react';
import ARLDropdown from '../ARLDropdown/ARLDropdown';
import ARLInputs from '../ARLInputs/ARLInputs';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { editSubtopicObjectiveConditions } from '../../api/arl_mapping';

const ARLCardHolder = (props) => {
  const {
    cardHeading,
    objectiveData,
    popOverObjectiveList,
    popOverSubTopicList,
    cardObjectiveId,
    selectedSubTopic,
    triggerUpdate,
  } = props;

  const [showCondition, setShowCondition] = useState(false);
  const [defineCondition, setDefineCondition] = useState(objectiveData?.condition ? objectiveData?.condition : false);
  const [condition, setCondition] = useState({
    readVelocity: objectiveData?.read_velocity ? objectiveData?.read_velocity : '',
    minQuestions: objectiveData?.min_questions ? objectiveData?.min_questions : '',
    recheckQuestions: objectiveData?.recheck_questions ? objectiveData?.recheck_questions : '',
    subTopicId: objectiveData?.prereq_sub_topic_id ? objectiveData?.prereq_sub_topic_id : -1,
    objectiveId: objectiveData?.prereq_objective_id ? objectiveData?.prereq_objective_id : -1,
  });
  const err = {};

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setCondition((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    console.log('defineCondition condition', defineCondition);
  }, [defineCondition]);

  const runValidation = async () => {
    // console.log('types', {
    //   rv: typeof condition.readVelocity,
    //   mq: typeof condition.minQuestions,
    //   rcheck: typeof condition.recheckQuestions,
    // });
    if (defineCondition) {
      if (+condition.subTopicId === -1) {
        toast.info('Please select sub-topic');
        err.subTopicId = 'Please select sub-topic';
      }
      if (+condition.objectiveId === -1) {
        toast.info('Please select objective');
        err.objectiveId = 'Please select objective';
      }
      if (String(condition.readVelocity).trim().length === 0) {
        toast.info('Please mention read velocity');
        err.readVelocity = 'Please mention read velocity';
      }
      if (String(condition.minQuestions).trim().length === 0) {
        toast.info('Please mention min questions');
        err.minQuestions = 'Please mention min questions';
      }
      if (String(condition.recheckQuestions).trim().length === 0) {
        toast.info('Please mention re-check questions');
        err.recheckQuestions = 'Please mention re-check questions';
      }
    }
  };

  // const resetForm = () => {
  //   setCondition((prevState) => {
  //     return { ...prevState, readVelocity: '', minQuestions: '', recheckQuestions: '', subTopicId: -1, objectiveId: -1 };
  //   });
  // };

  const handleCancel = async () => {
    if (defineCondition) {
      setCondition((prevState) => {
        return {
          ...prevState,
          readVelocity: objectiveData?.read_velocity,
          minQuestions: objectiveData?.min_questions,
          recheckQuestions: objectiveData?.recheck_questions,
          subTopicId: objectiveData?.prereq_sub_topic_id,
          objectiveId: objectiveData?.prereq_objective_id,
        };
      });
    }
    setShowCondition(false);
  };

  const handleSubmitCondition = async (e) => {
    e.preventDefault();
    await runValidation();
    if (Object.keys(err).length === 0) {
      const formData = {
        sub_topic_id: +selectedSubTopic,
        objective_id: +cardObjectiveId,
        condition: defineCondition,
        prereq_sub_topic_id: +condition.subTopicId,
        prereq_objective_id: +condition.objectiveId,
        read_velocity: +condition.readVelocity,
        min_questions: +condition.minQuestions,
        recheck_questions: +condition.recheckQuestions,
      };
      // console.log('condition defineCondition', formData);
      try {
        const result = await editSubtopicObjectiveConditions(formData);
        // console.log('result', result);
        if (result?.status) {
          toast.success('Condition updated successfully');
          setShowCondition(false);
          triggerUpdate();
        }
      } catch (err) {
        console.log('error in updating the objective condition', err);
        toast.error('Error in updating the objective condition');
      }
    } else {
      toast.error('Please provide required details');
    }
  };

  return (
    <div className="arl-single-card-cover">
      <div className="arl-card-container">
        <div className="arl-card-content-wrapper-text">{cardHeading}</div>
        <div className="arl-card-content-wrapper">
          <div className="arl-card-content">
            <div className="arl-card-content__text">Number of Content</div>
            <div className="arl-card-content__text">{objectiveData?.content_count || 0}</div>
          </div>
          <div className="arl-card-content">
            <div className="arl-card-content__text">Total Duration</div>
            <div className="arl-card-content__text">{objectiveData?.total_duration || '--'}</div>
          </div>
          <div className="arl-card-content" style={{ border: 'none' }}>
            <div className="arl-card-content__text" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              Condition
              {objectiveData?.condition ? (
                <div
                  style={{
                    fontSize: '12px',
                    color: '#757575',
                  }}>{`RV: ${objectiveData?.read_velocity || '--'}, MQ: ${objectiveData.min_questions || '--'} RQ: ${objectiveData.recheck_questions || '--'}`}</div>
              ) : null}
            </div>

            <button
              className={objectiveData?.condition ? 'arl-card-content__update-btn' : 'arl-card-content__btn'}
              onClick={() => setShowCondition(true)}>
              {objectiveData?.condition ? 'Update' : 'Define'}
            </button>
          </div>
        </div>
      </div>
      {showCondition ? (
        <div className="arl-card-condition-popup">
          <div className="arl-card-triangle"></div>
          <div className="arl-card-condition-wrapper">
            <div className="arl-card-condition-definition">
              <div className="arl-card-content__text">Define condition?</div>
              <label className="arl-card-content__text" style={{ cursor: 'pointer' }}>
                <input
                  type="checkbox"
                  style={{ marginLeft: '3px', cursor: 'pointer' }}
                  value={defineCondition}
                  checked={defineCondition}
                  onChange={() => setDefineCondition((prevVal) => (prevVal = !prevVal))}
                />
              </label>
            </div>
            <ARLDropdown
              defineCondition={defineCondition}
              name="subTopicId"
              default_option="Sub-topics"
              values={popOverSubTopicList}
              id_name="sub_topic_id"
              display_name="sub_topic_name"
              handleIdChange={handleInputs}
              selectedId={condition.subTopicId}
            />
            <ARLDropdown
              defineCondition={defineCondition}
              values={popOverObjectiveList}
              name="objectiveId"
              default_option="Objectives"
              id_name="objective_id"
              display_name="objective"
              handleIdChange={handleInputs}
              selectedId={condition.objectiveId}
            />
            <ARLInputs
              name="readVelocity"
              value={condition.readVelocity}
              textName="Read Velocity"
              handleInputs={handleInputs}
              defineCondition={defineCondition}
            />
            <ARLInputs
              name="minQuestions"
              value={condition.minQuestions}
              textName="Min Questions"
              handleInputs={handleInputs}
              defineCondition={defineCondition}
            />
            <ARLInputs
              name="recheckQuestions"
              value={condition.recheckQuestions}
              textName="Recheck Questions"
              handleInputs={handleInputs}
              defineCondition={defineCondition}
            />
            <div className="arl-card-condition__btn-container">
              <button className="arl-card-condition__cancel-btn" onClick={handleCancel}>
                Cancel
              </button>
              <button className="arl-card-condition__save-btn" onClick={handleSubmitCondition}>
                Save
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
    </div>
  );
};

export default ARLCardHolder;
