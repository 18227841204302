import React, { useState, useRef, useEffect } from 'react';
import RiseLoader from 'react-spinners/RiseLoader';
import Filter from '../../../components/Filter/Filter';
import promiseAllCall from '../../../utils/promise';
import ARLTable from '../../../components/ARLTable/ARLTable';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { fetchBoards, fetchSubjects, fetchCourse, fetchTopics } from '../../../api/master_data';
import { fetchARLList, fetchARLListCount } from '../../../api/arl_mapping';

const ARLMappingMainBar = () => {
  const dropdownObj = localStorage.getItem('selectedARLDropdowns');
  const selectedARLDropdowns = JSON.parse(dropdownObj);

  const [loading, setLoading] = useState(false);
  const [boards, setBoards] = useState([]);
  const [board_id, setBoardId] = useState(selectedARLDropdowns?.board_id ? selectedARLDropdowns?.board_id : -1);
  const [courses, setCourses] = useState([]);
  const [course_id, setCourseId] = useState(selectedARLDropdowns?.course_id ? selectedARLDropdowns?.course_id : -1);
  const [subjects, setSubjects] = useState([]);
  const [subject_id, setSubjectId] = useState(selectedARLDropdowns?.subject_id ? selectedARLDropdowns?.subject_id : -1);
  const [topics, setTopics] = useState([]);
  const [topic_id, setTopicId] = useState(selectedARLDropdowns?.topic_id ? selectedARLDropdowns?.topic_id : -1);
  const [searchTerm, setSearchTerm] = useState('');
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState(0);

  // Pagination
  const initialRender = useRef();
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 20;

  // update localstorage
  useEffect(() => {
    localStorage.setItem('selectedARLDropdowns', JSON.stringify({ board_id, course_id, subject_id, topic_id }));
  }, [board_id, course_id, subject_id, topic_id]);

  useEffect(() => {
    const fetchAll = async () => {
      setLoading(true);
      setBoards(await fetchBoards());
      setCourses(await fetchCourse(board_id));
      setSubjects(await fetchSubjects(board_id, course_id));
      setTopics(await fetchTopics(board_id, course_id, subject_id, null));
      setLoading(false);
    };
    try {
      fetchAll();
    } catch (err) {
      console.log('err in fetchAll - ARL Mapping', err);
      toast.error('Something went wrong try again');
      if (err?.message) {
        toast.error(err.message);
      }
      // eslint-disable-next-line
      if (err && err.response.status == 400 && err.response.data && err.response.data.type === 'TOKEN_ERR') {
        toast.info('Session expired, Please login once again');
        setTimeout(() => {
          return (window.location.href = '/login');
        }, 3000);
      }
      setLoading(false);
    }
  }, [board_id, course_id, subject_id]);

  useEffect(() => {
    const getTableData = async () => {
      setLoading(true);
      const callAll = await promiseAllCall([
        fetchARLList(board_id, course_id, subject_id, topic_id, searchTerm, limit, offset),
        fetchARLListCount(board_id, course_id, subject_id, topic_id, searchTerm),
      ]);
      // console.log('callAll', callAll);
      const initialTableData = callAll.length && callAll[0];
      if (callAll && callAll?.length > 0) {
        if (callAll[1] !== undefined && !callAll[1]?.message) {
          setCount(+callAll[1]);
        }
      }
      if (initialRender.current) {
        setTableData((prevState) => {
          return [...prevState, ...initialTableData];
        });
      } else {
        if (initialTableData.length > 0) {
          setTableData(initialTableData);
        }
        initialRender.current = true;
      }
      setLoading(false);
    };
    try {
      getTableData();
    } catch (err) {
      console.log('err getTableData- ARLMapping Mainbar', err);
      toast.error('Something went wrong try again');
      if (err?.message) {
        toast.error(err.message);
      }
      if (err?.response?.data?.type === 'TOKEN_ERR') {
        toast.info('Session expired, Please login once again');
        setTimeout(() => {
          return (window.location.href = '/login');
        }, 3000);
      }
      setLoading(false);
    }
  }, [board_id, course_id, subject_id, topic_id, searchTerm, offset]);

  const handleIdChange = async (e) => {
    const { name, value } = e.target;
    if (name === 'Board') {
      setBoardId(value);
      //   console.log('Test Dropdown: Board, ', value);
      setTableData([]);
      setCourseId(-1);
      setSubjectId(-1);
      setTopicId(-1);
    } else if (name === 'Course') {
      //   console.log('Test Dropdown: Course, ', value);
      setCourseId(value);
      setTableData([]);
      setSubjectId(-1);
      setTopicId(-1);
    } else if (name === 'Subject') {
      //   console.log('Test Dropdown: Subject, ', value);
      setSubjectId(value);
      setTableData([]);
      setTopicId(-1);
    } else if (name === 'Topic') {
      //   console.log('Test Dropdown: Topic, ', value);
      setTopicId(value);
      setTableData([]);
    } else if (name === 'Search-bar') {
      setSearchTerm(value);
      setTableData([]);
      // setOffset((prevVal) => (prevVal = 0));
    }
    setOffset((prevVal) => (prevVal = 0));
  };

  return (
    <>
      {loading ? (
        <div className="loading__spinners">
          <RiseLoader
            color="#CC435F"
            margin={5}
            cssOverride={{
              position: 'absolute',
              top: '50%',
              left: '45%',
              zIndex: 1,
            }}
            loading={loading}
            size={50}
            speedMultiplier={1}
          />
        </div>
      ) : null}
      <div className="main-bar">
        <div className="main-bar__header">
          <div className="page-name">ARL Mapping</div>
        </div>
        <div className="main-bar__filter-component">
          <Filter
            boards={boards}
            courses={courses}
            subjects={subjects}
            topics={topics}
            handleIdChange={handleIdChange}
            searchTerm={searchTerm}
            initialRender={initialRender}
            board_id={board_id}
            course_id={course_id}
            subject_id={subject_id}
            topic_id={topic_id}
            moduleName="arlMapping"
            modalName="arlMapping"
          />
        </div>
        <div className="explainer-videos-main-bar__table-component">
          <ARLTable
            tableData={tableData}
            setTableData={setTableData}
            count={count}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
    </>
  );
};

export default ARLMappingMainBar;
