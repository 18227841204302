import React from 'react';
import './Filter.css';
import DropDown from '../DropDown/DropDown';
import SearchBar from '../SearchBar/SearchBar';
import MoreFilters from '../MoreFilters/MoreFilters';
import FilterShape from '../../Assets/ContentLibrary/ContentLibrary/MainBar/filter-shape.png';
import ActivityStackMoreFilters from '../ActivityStackMoreFilters/ActivityStackMoreFilters';
import ExplainerVideoMoreFilters from './../ExplainerVideoMoreFilters/ExplainerVideoMoreFilters';

const Filter = (props) => {
  const {
    boards,
    courses,
    subjects,
    subSubjects,
    topics,
    subTopics,
    handleIdChange,
    searchTerm,
    openModal,
    handleModalOpenClose,
    modalData,
    setModalData,
    modalName,
    filterName,
    audioLanguage,
    subtitle,
    authors,
    objective,
    fileType,
    setObjective,
    setFileType,
    setAuthors,
    activityCategory,
    activityMode,
    activityType,
    activityModalData,
    setActivityModalData,
    setActivityCategory,
    setActivityMode,
    setActivityType,
    setOffset,
    initialRender,
    board_id,
    course_id,
    subject_id,
    topic_id,
    subTopicId,
    subSubject_id,
    audio_language_id,
    moduleName,
    subtitle_language_id,
  } = props;

  return (
    <div className="filter">
      <DropDown
        name="Board"
        default_option="Board"
        values={boards}
        id_name="board_id"
        display_name="board_name"
        handleIdChange={handleIdChange}
        selectedId={board_id}
      />
      <DropDown
        name="Course"
        default_option="Course"
        values={courses}
        id_name="course_id"
        display_name="course_name"
        handleIdChange={handleIdChange}
        selectedId={course_id}
      />
      <DropDown
        name="Subject"
        default_option="Subject"
        values={subjects}
        id_name="subject_id"
        display_name="subject_name"
        handleIdChange={handleIdChange}
        selectedId={subject_id}
      />
      {!filterName === 'ExplainerVideosFilter' ? (
        <DropDown
          name="Sub-subject"
          default_option="Sub-subject"
          values={subSubjects}
          id_name="sub_subject_id"
          display_name="sub_subject_name"
          handleIdChange={handleIdChange}
          display_eng_name="sub_subject_name_eng"
          selectedId={subSubject_id}
        />
      ) : null}
      <DropDown
        name={filterName ? 'Audio Language' : 'Topic'}
        default_option={filterName ? 'Audio Language' : 'Topic'}
        values={filterName ? audioLanguage : topics}
        id_name={filterName ? 'id' : 'topic_id'}
        display_name={filterName ? 'name' : 'topic_name'}
        display_eng_name={filterName ? 'name' : 'topic_name_eng'}
        handleIdChange={handleIdChange}
        selectedId={filterName ? audio_language_id : topic_id}
      />
      {moduleName !== 'arlMapping' ? (
        <DropDown
          name={filterName ? 'Subtitle' : 'Sub-topic'}
          default_option={filterName ? 'Subtitle' : 'Sub-topic'}
          values={filterName ? subtitle : subTopics}
          id_name={filterName ? 'id' : 'sub_topic_id'}
          display_name={filterName ? 'name' : 'sub_topic_name'}
          display_eng_name={filterName ? 'name' : 'sub_topic_name_eng'}
          handleIdChange={handleIdChange}
          selectedId={filterName ? subtitle_language_id : subTopicId}
        />
      ) : null}
      <SearchBar name="Search-bar" searchTerm={searchTerm} handleIdChange={handleIdChange} />
      <button
        className="filter__popup"
        onClick={handleModalOpenClose}
        disabled={modalName === 'ExplainerVideosModal' || modalName === 'arlMapping' ? true : false}
        style={{ opacity: modalName === 'ExplainerVideosModal' || modalName === 'arlMapping' ? '0.4' : null }}>
        <img src={FilterShape} alt="open more filters tab" />
      </button>
      {openModal &&
        (modalName === 'ActivityStackModal' ? (
          <ActivityStackMoreFilters
            openModal={openModal}
            handleModalOpenClose={handleModalOpenClose}
            authors={authors}
            activityCategory={activityCategory}
            activityMode={activityMode}
            activityType={activityType}
            setAuthors={setAuthors}
            setActivityCategory={setActivityCategory}
            setActivityMode={setActivityMode}
            setActivityType={setActivityType}
            activityModalData={activityModalData}
            setActivityModalData={setActivityModalData}
          />
        ) : modalName === 'ContentFilterModal' ? (
          <MoreFilters
            openModal={openModal}
            handleModalOpenClose={handleModalOpenClose}
            modalData={modalData}
            setModalData={setModalData}
            authors={authors}
            objective={objective}
            fileType={fileType}
            setAuthors={setAuthors}
            setObjective={setObjective}
            setFileType={setFileType}
            setOffset={setOffset}
            initialRender={initialRender}
          />
        ) : modalName === 'ExplainerVideosModal' ? (
          <ExplainerVideoMoreFilters openModal={openModal} handleModalOpenClose={handleModalOpenClose} />
        ) : null)}
    </div>
  );
};

export default Filter;
