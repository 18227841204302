import React from 'react';
import SideBar from '../../../components/SideBar/SideBar';
import DisplayARLDetail from './DisplayARLDetail/DisplayARLDetail';

const ARLMappingDetails = () => {
  return (
    <div className="main-page">
      <div className="main-page__bar-container">
        <SideBar />
        <DisplayARLDetail />
      </div>
    </div>
  );
};

export default ARLMappingDetails;
