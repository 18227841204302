import React from 'react';

const ARLDropdown = (props) => {
  const { defineCondition, name, default_option, values, id_name, display_name, handleIdChange, selectedId } = props;
  return (
    <select
      className="arl-dropdown"
      style={{ opacity: !defineCondition ? 0.5 : 1, pointerEvents: !defineCondition }}
      disabled={!defineCondition}
      name={name}
      value={selectedId}
      onChange={handleIdChange}>
      <option value={-1}>{default_option}</option>
      {values.map((ele) => {
        return (
          <option key={ele[id_name]} value={ele[id_name]}>
            {ele[display_name]}
          </option>
        );
      })}
    </select>
  );
};

export default ARLDropdown;
